import { Flex, Image, VStack } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { getAuthor } from '../../utils'
import Text from '../typography/Text'

interface Props {
    seed: string
}

const Author: React.FC<Props> = ({ seed }) => {
    const author = useMemo(() => getAuthor(seed), [seed])

    const { firstname, lastname, avatar, bio } = author

    return (
        <Flex>
            <Flex>
                <Image
                    src={avatar}
                    boxSize="65px"
                    alt="author avatar"
                    rounded="full"
                />
            </Flex>
            <VStack w="full" alignItems="flex-start" mt={4} ml={6}>
                <Text
                    fontSize="xl"
                    color="primary.500"
                    fontWeight="bold"
                    itemProp="author"
                >
                    {firstname} {lastname}
                </Text>
                <Text>{bio}</Text>
            </VStack>
        </Flex>
    )
}

export default Author
