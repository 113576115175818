import { Divider, Flex, HStack, VStack } from '@chakra-ui/react'
import type { HeadFC } from 'gatsby'
import * as React from 'react'
import AppReview from '../components/apps/AppReview'
import SubHeading from '../components/typography/SubHeading'
import Text from '../components/typography/Text'
import Layout from '../layout/Layout'
import appReviews from '../../content/workout-app-reviews/review.json'
import BannerImage from '../images/banner.jpg'
import Guarantee from '../components/footer/Guarantee'
import Author from '../components/author/Author'
import AppDiscover from '../components/apps/AppDiscover'
import PageContainer from '../components/container/PageContainer'
import featured from '../../content/lists/featured.json'
import AppMockup from '../components/apps/AppMockup'
import SEO from '../components/SEO'

const Page = () => {
    return (
        <Layout extraFooter={<Guarantee />} fullWidth={true}>
            <Flex position="relative">
                <Flex
                    position="absolute"
                    height="full"
                    bg="primary.700"
                    w="full"
                    bgImage={BannerImage}
                    backgroundSize="cover"
                    backgroundPosition="center"
                    boxShadow="inner"
                    justifyContent="center"
                    alignItems="center"
                    bgColor="black"
                    filter="opacity(0.4) blur(3px)"
                ></Flex>
                <PageContainer>
                    <HStack justifyContent="center" alignItems="center">
                        <AppMockup screenshot={appReviews[0].imageGallery[1]} />
                        <AppMockup screenshot={appReviews[1].imageGallery[0]} />
                        <AppMockup screenshot={appReviews[2].imageGallery[0]} />
                    </HStack>
                </PageContainer>
            </Flex>
            <PageContainer as="section">
                <VStack alignItems="flex-start" spacing={4}>
                    <SubHeading as="h2">
                        THE 5 BEST WORKOUT APPS YOU NEED IN 2022
                    </SubHeading>
                    <Divider />
                    <Text>
                        Nowadays, there are more than 2.5 million apps on Google
                        Play and Apple Store. In the midst of so much choice, it
                        becomes difficult to choose the best ones.
                    </Text>
                    <Text>But don't worry, because we got you covered!</Text>
                    <Text>
                        All of these apps are free; however, they contain paid
                        options that you can access. Also, all of these apps are
                        available for both Android and IOS.
                    </Text>
                    <SubHeading as="h2">HOW TO CHOSE A WORKOUT APP</SubHeading>
                    <Text>
                        All of these apps are free; however, they contain paid
                        options that you can access. Also, all of these apps are
                        available for both Android and IOS.
                    </Text>
                    <SubHeading as="h2">OUR SELECTION</SubHeading>
                    <Text>
                        Whether you are looking for the best way to lose weight,
                        bulk-up, eat better or simply, keep you motivated, there
                        are a ton of great app options out there. However, it
                        can be overwhelming to know where to start. That's why
                        we have rounded up the 5 best workout app to help you
                        smashing your goals!
                    </Text>
                </VStack>
            </PageContainer>
            {appReviews.map((review, index) => {
                return <AppReview key={index} data={review} />
            })}
            <PageContainer>
                <Divider mb={10} />
                <Author seed="review" />
            </PageContainer>
            <AppDiscover app={appReviews[0]} list={featured} />
        </Layout>
    )
}

export default Page

export const Head: HeadFC = (props) => {
    const { location } = props

    const title = 'The best workout apps in 2022'
    const description =
        "The best workout apps have something to offer everyone from beginners to serious exercise addicts. Here's the top workout apps for iPhones and Android."
    const summary = description

    return (
        <SEO
            description={description}
            location={location.pathname}
            summary={summary}
            title={title}
        />
    )
}
