import { Flex, HStack, Stack, useBreakpointValue } from '@chakra-ui/react'
import { chunk } from 'lodash'
import React from 'react'
import PageContainer from '../container/PageContainer'
import AppMockup from './AppMockup'

interface Props {
    images: string[]
    chunkNumber?: number
}

const AppGallery: React.FC<Props> = ({ images = [], chunkNumber = 0 }) => {
    const chunkSize = useBreakpointValue({ base: 2, sm: 3 })
    const imageSelections = chunk(images, chunkSize)

    if (!imageSelections[chunkNumber]) {
        return null
    }

    return (
        <Flex justifyContent="center" position="relative">
            <PageContainer>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {imageSelections[chunkNumber].map((src, index) => {
                        return <AppMockup key={index} screenshot={src} />
                    })}
                </Stack>
            </PageContainer>
        </Flex>
    )
}

export default AppGallery
