import { Flex, List, ListIcon, ListItem } from '@chakra-ui/react'
import { FaCircle } from 'react-icons/fa'
import { BsFillCircleFill } from 'react-icons/bs'

import React from 'react'

interface Props {
    items: string[]
    color?: string
}

const BulletList: React.FC<Props> = ({ items, color = 'secondary.400' }) => {
    return (
        <List spacing={4}>
            {items.map((item, index) => {
                return (
                    <ListItem as={Flex} key={index}>
                        <ListIcon
                            as={FaCircle}
                            color={color}
                            mt={0.5}
                            width="10px"
                        />
                        {item}
                    </ListItem>
                )
            })}
        </List>
    )
}

export default BulletList
