import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'
import CardBg from '../images/card-bg.png'

interface Props extends BoxProps {
    children: React.ReactNode
}

const Card: React.FC<Props> = ({ children, ...props }) => {
    return (
        <Box
            {...props}
            boxShadow="md"
            borderRadius="md"
            p={4}
            borderWidth={0}
            borderColor="blackAlpha.100"
            bg="highlight.50"
        >
            {children}
        </Box>
    )
}

export default Card
