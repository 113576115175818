import { Box } from '@chakra-ui/react'
import React from 'react'
import SubHeading from '../typography/SubHeading'
import Text from '../typography/Text'

interface Props {}

const Guarantee: React.FC<Props> = () => {
    return (
        <Box px={16} py={16} bg="primary.800">
            <SubHeading color="whiteAlpha.900" mb={4}>
                HONEST, OBJECTIVE, LAB-TESTED REVIEWS
            </SubHeading>
            <Text color="whiteAlpha.800">
                BestApps.com is a leading authority on technology, delivering
                lab-based, independent reviews of the latest products and
                services. Our expert industry analysis and practical solutions
                help you make better buying decisions and get more from
                technology.
            </Text>
        </Box>
    )
}

export default Guarantee
