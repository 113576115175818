import { Box, Flex, HStack, Stack } from '@chakra-ui/react'
import { IAppItemFullDetail } from 'google-play-scraper'
import React from 'react'
import { getApps } from '../../utils'
import PageContainer from '../container/PageContainer'
import Heading from '../typography/Heading'
import AppCard from './AppCard'

interface Props {
    app: IAppItemFullDetail
    list: IAppItemFullDetail[]
}

const AppDiscover: React.FC<Props> = ({ app, list }) => {
    const apps = getApps(list, 3, app)

    return (
        <Box bg="blackAlpha.50" py={10} as="aside">
            <PageContainer>
                <Flex justifyContent="center" mb={10}>
                    <Heading>Discover more apps</Heading>
                </Flex>

                <Flex justifyContent="center" w="full">
                    <Stack
                        spacing={6}
                        direction={{ base: 'column', md: 'row' }}
                    >
                        {apps.map((anApp, index) => {
                            return <AppCard key={index} data={anApp} />
                        })}
                    </Stack>
                </Flex>
            </PageContainer>
        </Box>
    )
}

export default AppDiscover
