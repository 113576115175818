import { Image, Link } from '@chakra-ui/react'
import React from 'react'
import StoreImage from '../../images/download-appstore.svg'

interface Props {
    url: string
}

const AppleStoreButton: React.FC<Props> = ({ url }) => {
    return (
        <Link isExternal={true} href={url} itemProp="downloadUrl">
            <Image
                src={StoreImage}
                height="60px"
                alt="download on the app store"
            />
        </Link>
    )
}

export default AppleStoreButton
