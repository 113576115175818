import { Box, Flex, HStack, Link, VStack } from '@chakra-ui/react'
import React from 'react'
import { getStoreUrls } from '../../utils'
import BulletList from '../BulletList'
import AppleStoreButton from '../buttons/AppleStoreButton'
import GoogleStoreButton from '../buttons/GoogleStoreButton'
import Card from '../Card'
import PageContainer from '../container/PageContainer'
import PlayStoreImage from '../image/PlayStoreImage'
import { AppReviewType } from '../types/DataTypes'
import SubHeading from '../typography/SubHeading'
import Text from '../typography/Text'
import AppGallery from './AppGallery'

interface Props {
    data: AppReviewType
}

const AppReview: React.FC<Props> = ({ data }) => {
    const {
        title,
        logoUrl,
        highlights,
        appStoreUrl,
        playStoreUrl,
        imageGallery,
        whyWeChooseIt,
        pros,
        cons,
        bodyText,
    } = data

    const storeUrls = [
        {
            url: appStoreUrl,
            component: AppleStoreButton,
        },
        {
            url: playStoreUrl,
            component: GoogleStoreButton,
        },
    ].filter((url) => url.url)

    return (
        <Box
            py={10}
            itemScope
            itemType="https://schema.org/SoftwareApplication"
        >
            <PageContainer py={0}>
                <SubHeading as="h3" fontSize="2xl">
                    {title}
                </SubHeading>
            </PageContainer>
            <AppGallery images={imageGallery} />
            <PageContainer>
                <VStack alignItems="flex-start" spacing={10}>
                    <Flex w="full" justifyContent="center" position="relative">
                        <Box position="absolute" top={-20}>
                            <PlayStoreImage
                                boxShadow="md"
                                src={logoUrl}
                                boxSize="60px"
                                borderRadius={10}
                                alt="app icon"
                                itemProp="image"
                            />
                        </Box>
                        <HStack spacing={6} w="full" justifyContent="center">
                            {storeUrls.map((storeData, index) => {
                                return (
                                    <storeData.component
                                        url={storeData.url}
                                        key={index}
                                    />
                                )
                            })}
                        </HStack>
                    </Flex>

                    <Text>
                        <Text as="span" fontWeight="bold">
                            Why we choose it:{' '}
                        </Text>
                        {whyWeChooseIt}
                    </Text>
                    <HStack spacing={6} alignItems="flex-start" w="full">
                        <Card w="full">
                            <SubHeading as="h4" fontSize="xl" mb={4}>
                                Pros
                            </SubHeading>
                            <BulletList items={pros} color="green.300" />
                        </Card>
                        <Card w="full">
                            <SubHeading as="h4" fontSize="xl" mb={4}>
                                Cons
                            </SubHeading>
                            <BulletList items={cons} color="red.300" />
                        </Card>
                    </HStack>
                    {bodyText.map((paragraph, index) => {
                        return <Text key={index}>{paragraph}</Text>
                    })}
                </VStack>
            </PageContainer>
        </Box>
    )
}

export default AppReview
